// Usage:
//  import { animateCSS } from "helpers/animation"
// https://minimul.com/lets/watch/Wq5b6MfbCp4/animate-your-stimulusjs-components

export function animateCSS({ element, classes = [], enabled = true, callback }) {
  if (!element) { return }
  if (enabled) {
    const finalClasses =  ['animate__animated', ...(classes.map((it) => { return 'animate__'.concat(it) }))]
    element.classList.add(...finalClasses)

    function handleAnimationEnd() {
      element.classList.remove(...finalClasses)
      element.removeEventListener('animationend', handleAnimationEnd)

      if (typeof callback === 'function') callback(element)
    }

    element.addEventListener('animationend', handleAnimationEnd)
  } else if (typeof callback === 'function') {
    callback(element)
  }
}
