// TODO: Remove this or merge w/ breathing_controller

import { AppController } from './app_controller'
import EventBus from 'js-event-bus'; const eventBus = EventBus()

export default class extends AppController {
  static targets = ['video', 'pauseButton', 'breathing']

  connect() {
    eventBus.on('breathingStarted', (_) => this.breathingStarted())
    eventBus.on('breathingStopped', (_) => this.breathingStopped())
    this.pauseButtonTarget.style.opacity = 0
  }

  disconnect() {
    eventBus.detach('breathing*')
  }

  breathingStarted(e) {
    let buttonController = this.application.getControllerForElementAndIdentifier(
      this.pauseButtonTarget,
      "button"
    )

    buttonController.state = 'default'
  }

  breathingStopped(e) {
    let buttonController = this.application.getControllerForElementAndIdentifier(
      this.pauseButtonTarget,
      "button"
    )

    buttonController.state = 'finished'
  }

  stop(e) {
    this.pauseButtonTarget.style.opacity = 0
    this.showModal('setup', e)
    this.videoTarget.pause()
    this.breathingController.stop()
  }

  start(e) {
    this.getModal('setup').close()
    if (this.videoTarget.paused) {
      this.videoTarget.currentTime = 0
      this.videoTarget.play()
    }
    this.pauseButtonTarget.style.opacity = 1

    this.breathingController.count = this.element.querySelector("[data-target='counter.input']").value
    this.breathingController.start()
  }

  goBack(e) {
    window.history.back()
  }

  updateBackground(e) {
    var wasPlaying = !this.videoTarget.paused
    this.videoTarget.firstElementChild.src = e.currentTarget.value
    this.videoTarget.load()
    if (wasPlaying) {
      this.videoTarget.play()
    }
  }

  get breathingController() {
    return this.application.getControllerForElementAndIdentifier(
      this.breathingTarget,
      "breathing"
    )
  }
}
