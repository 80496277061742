import { AppController } from './app_controller'
import { storeGet } from 'helpers/storage'

export default class extends AppController {
  static targets = ['welcome']

  connect() {
    if (this.profileName) {
      this.welcomeTarget.innerHTML = `Welcome ${this.profileName}!`
    }
  }

  tappedGame(e) {
    if (e.currentTarget.dataset.href) {
      // Turbolinks doesn't work well here, so disabling
      window.location = e.currentTarget.dataset.href
    }
  }

  get profileName() {
    return storeGet('profile', 'name')
  }
}
