import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input', 'add', 'sub' ]

  connect() {
    this.update()
  }

  update() {
    if (!isNaN(this.max)) {
      if (this.value > this.max) {
        this.value = this.max
      }

      this.toggleDisableEl(this.addTarget, this.value >= this.max)
    }

    if (!isNaN(this.min)) {
      if (this.value < this.min) {
        this.value = this.min
      }

      this.toggleDisableEl(this.subTarget, this.value <= this.min)
    }
  }

  incr(e) {
    if (isNaN(this.max)) {
      this.value++
    } else if (this.value < this.max) {
      this.value++
    }

    this.update()
  }

  decr(e) {
    if (isNaN(this.min)) {
      this.value--
    } else if (this.value > this.min) {
      this.value--
    }

    this.update()
  }

  toggleDisableEl(el, shouldDisable) {
    el.disabled = shouldDisable
    el.classList.toggle('cursor-not-allowed', shouldDisable)
    el.classList.toggle('hoverable', !shouldDisable)
    el.classList.toggle('opacity-50', shouldDisable)
  }

  get min() {
    return parseInt(this.inputTarget.min)
  }

  get max() {
    return parseInt(this.inputTarget.max)
  }

  get value() {
    return parseInt(this.inputTarget.value)
  }

  set value(newValue) {
    this.inputTarget.value = newValue
    this.update()
  }
}
