import { AppController } from './app_controller'
import { storeGet, storeGetInt, storeSet } from 'helpers/storage'
import { avatarAssetPath } from 'helpers/assets'
import { Confetti } from 'confetti'
import { animateCSS } from "helpers/animation"

export default class extends AppController {
  static targets = ['avatar', 'welcome', 'btn']

  connect() {
    setTimeout(() => {
      Confetti.start(3000, { count: 50 })
    }, 1000)
    animateCSS({ element: this.avatarTarget, classes: ['bounceInDown'] })
    animateCSS({ element: this.welcomeTarget, classes: ['bounceInUp'] })
    animateCSS({ element: this.btnTarget, classes: ['fadeInUp'] })
  }

  disconnect() {
    Confetti.remove()
  }

  pop() {
    if (!Confetti.isRunning()) {
      Confetti.start(1500, { count: 10 })
    }
  }
}
