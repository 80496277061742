// Currently built for 5 items, sad to happy

import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'rangeable' ]

  connect() {
    this.update()
  }

  jump(e) {
    this.update()
  }

  update() {
    var currentIndex = parseInt(this.element.querySelector('input:checked')?.dataset['index'])

    this.rangeableTargets.forEach((el, i) => {
      el.classList.add(...this.clsForIndex(i, 300, 'hover:'))
      if (currentIndex == i) {
        el.classList.add(...this.clsForIndex(i, 400))
        el.classList.remove('bg-dark-5', 'dark:bg-dark-50')

        el.classList.remove('hoverable')
      } else {
        el.classList.remove(...this.clsForIndex(i, 400))

        el.classList.add('bg-dark-5', 'dark:bg-dark-50')

        el.classList.add('hoverable')
      }
    })
  }

  clsForIndex(index, range, prefix = "") {
    var theme = this.data.get('theme');
    if (theme == 'rainbow') {
      switch(index) {
        case 0:
          return [prefix + 'bg-orange-' + range]
          break;
        case 1:
          return [prefix + 'bg-yellow-' + range]
          break;
        case 2:
          return [prefix + 'bg-green-' + range]
          break;
        case 3:
          return [prefix + 'bg-blue-' + range]
          break;
        case 4:
          return [prefix + 'bg-purple-' + range]
          break;
      }
    } else {
      return [prefix + 'btn-primary', prefix + 'text-inv']
    }
  }

  get rangeableCount() {
    return this.rangeableTargets.length
  }
}
