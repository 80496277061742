import { AppController } from './app_controller'
import { speak } from "helpers/speech"

export default class extends AppController {
  tappedCommunication(e) {
    let modalController = this.showModal('communication_picker', e)
    let currentTarget = e.currentTarget
    let modalContentChild = modalController.contentTarget.firstElementChild
    modalContentChild.innerHTML = currentTarget.innerHTML

    modalContentChild.firstElementChild.classList.add('h-full')
    modalController.focus(0)

    if (this.speechEnabled) { speak(currentTarget.innerText) }
  }

  get filterableController() {
    return this.getController(this.element, "filterable")
  }

  get speechEnabled() {
    return this.element.querySelector('input[name=enable_speech]:checked').value == "true"
  }
}
