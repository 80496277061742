export function paramGet(key) {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.get(key)
}

export function paramHas(key) {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  return urlParams.has(key)
}

export function paramIsNull(key) {
  return !paramHas(key)
}
