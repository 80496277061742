export const presence = arr => arr?.length > 0 ? arr : null
export const sample = arr => arr[Math.floor(Math.random() * arr.length)]
export const sampleNot = (arr, not) => sample(arr.filter(it => it != not))

export const shuffle = arr => {
  for (var i = arr.length - 1; i > 0; i--) {
    var j = Math.floor(Math.random() * (i + 1));
    var temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }

  return arr
}
