import { AppController } from './app_controller'
import { storeGet, storeGetInt, storeSet } from 'helpers/storage'
import { avatarAssetPath } from 'helpers/assets'

export default class extends AppController {
  static targets = ['name', 'avatar' ]

  connect() {
    this.update()
  }

  update() {
    if (this.hasNameTarget && this.name) {
      this.nameTarget.innerHTML = this.name
    }
    if (this.color) {
      this.avatarTarget.style.backgroundColor = this.color
    }
    if (this.avatarId) {
      this.avatarTarget.firstElementChild.src = avatarAssetPath(this.avatarId)
    }
  }

  get avatarId() {
    return storeGetInt('profile', 'avatar-id')
  }

  get name() {
    return storeGet('profile', 'name')
  }

  get color() {
    return storeGet('profile', 'color')
  }
}
