import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'img', 'name', 'usedFor', 'feelsLike' ]

  setTool(tool) {
    this.nameTarget.innerHTML = tool.name
    this.imgTarget.src = tool.bg
    this.feelsLikeTarget.innerHTML = tool.feelsLike
    this.usedForTarget.innerHTML = tool.usedFor
  }
}
