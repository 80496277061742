import ifEmoji from 'if-emoji'
import { shuffle } from "helpers/array"

const DEFAULT_EMOJIS = [
  '😀',
  '😁',
  '😊',
  '😍',
  '😎',
  '😛',
  '🙃',
  '🤓',
  '🤠',
  '🤩',
  '🥳',
]

export function getFunEmojis(...additionalEmojis) {
  return shuffle(DEFAULT_EMOJIS.concat(additionalEmojis).filter((e) => ifEmoji(e) ))
}

export function didCollide(x1, y1, x2, y2, distance) {
  return Math.abs(x1 - x2) < distance && Math.abs(y1 - y2) < distance
}
