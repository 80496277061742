import { Controller } from "stimulus"
import { storeGet, storeSet } from 'helpers/storage'

export default class extends Controller {
  connect() {
    if (this.diffKey) {
      this.element.querySelector(`input[name=game_diff][value="${this.diffKey}"]`).setAttribute('checked', true)
    }
  }

  selectedDiff(e) {
    this.diffKey = e.target.value
  }

  set diffKey(value) {
    return storeSet(value, 'games', 'diff')
  }

  get diffKey() {
    return storeGet('games', 'diff') || '2'
  }
}
