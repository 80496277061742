const NAMESPACE = "com.mydentistguide"

export function storeClear() {
  window.localStorage.clear()
}

export function storeLength() {
  return window.localStorage.length
}

export function storeGet(...keys) {
  return window.localStorage.getItem(keyLookup(keys))
}

export function storeRemove(...keys) {
  return window.localStorage.removeItem(keyLookup(keys))
}

export function storeGetInt(...keys) {
  return parseInt(window.localStorage.getItem(keyLookup(keys)))
}

export function storeGetJSON(...keys) {
  return JSON.parse(window.localStorage.getItem(keyLookup(keys)))
}

export function storeMatches(...keys) {
  let matchingKey = keyLookup(keys)
  let keyLength = matchingKey.length
  return Object.entries(window.localStorage)
    .filter(it => it[0].substring(0, keyLength) == matchingKey)
    .map(it => [it[0], it[1]])
}

export function storeIsNull(...keys) {
  return storeGet(keys) === null
}

export function storeSet(value, ...keys) {
  window.localStorage.setItem(keyLookup(keys), value)
}

export function storeSetJSON(value, ...keys) {
  window.localStorage.setItem(keyLookup(keys), JSON.stringify(value))
}

// private

function keyLookup(keys) {
  return NAMESPACE.concat('.').concat(keys.join('.'))
}
