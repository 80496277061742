import { Controller } from "stimulus"

export default class extends Controller {
  dismiss(e) {
    var currentTarget = e.currentTarget
    currentTarget.parentElement.classList.add('transition', 'opacity-0', 'transition-opacity', 'duration-300', 'ease-out')

    setTimeout(() => {
      currentTarget.parentElement.remove()
    }, 300)
  }
}
