import { Controller } from "stimulus"
import { storeGetJSON, storeSetJSON } from 'helpers/storage'
import EventBus from 'js-event-bus'; const eventBus = EventBus()

export default class extends Controller {
  static targets = ['item']

  connect() {
    this.checkedClasses = this.data.get('classes') || 'active text-inv default-duration';
    this.update()
  }

  update() {
    this.itemTargets.forEach((el, i) => {
      var shouldCheck = this.storeGetChecked(el.dataset.id)
      this.setChecked(el, shouldCheck)
      el.querySelector('input').checked = shouldCheck
    })
  }

  toggleActive(e) {
    this.setChecked(e.currentTarget.parentElement, e.currentTarget.checked)
    this.storeSetChecked(e.currentTarget.parentElement.dataset.id, e.currentTarget.checked)
  }

  setChecked(el, shouldCheck) {
    let splitClasses = this.checkedClasses.split(' ')
    for (var i = 0; i < splitClasses.length; i++){
      el.classList.toggle(splitClasses[i], shouldCheck)
    }
    el.querySelector('svg.icon')?.classList.toggle('icon-inv', shouldCheck)
  }

  storeSetChecked(id, shouldCheck) {
    if (!id) { return }
    if (shouldCheck) {
      this.checkedIds.push(id)
    } else {
      this.checkedIds = this.checkedIds.filter(it => it != id)
    }

    storeSetJSON(this.checkedIds, this.storeKey)

    if (this.eventName) {
      eventBus.emit(this.eventName)
    }
  }

  storeGetChecked(id) {
    if (!this.checkedIds) {
      this.checkedIds = [...new Set(storeGetJSON(this.storeKey) || [])]
    }

    return this.checkedIds.includes(`${id}`)
  }

  get eventName() {
    return this.data.get('event')
  }

  get storeKey() {
    return this.data.get('storeKey') || `checkable.${this.element.id}`
  }
}
