import { Controller } from "stimulus"

export class AppController extends Controller {
  handleAction(e) {
    var currentTarget = e.currentTarget

    if (currentTarget.dataset.modal) {
      this.showModal(currentTarget.dataset.modal, e)
    }
  }

  closeModal(modalId, e) {
    this.getModal(modalId).close(e)
  }

  showModal(modalId, e) {
    let modalController = this.getModal(modalId)
    modalController.open(e)

    return modalController
  }

  getController(el, name) {
    return this.application.getControllerForElementAndIdentifier(
      el,
      name
    )
  }

  getModal(modalId) {
    return this.getController(document.getElementById(modalId), "modal")
  }

  async setAttributes(el, attrs, prefix) {
    Object.entries(attrs).forEach((args) => {
      if (typeof args[1] === 'object') {
        this.setAttributes(el, args[1], args[0])
      } else {
        let prefixedKey = this.prefixKey(args[0], prefix)
        if (el.hasAttribute(prefixedKey)) {
          this.element.setAttribute(prefixedKey, el.getAttribute(prefixedKey) + ' ' + args[1].replace(/\+/g, ' '))
        } else {
          this.element.setAttribute(prefixedKey, args[1].replace(/\+/g, ' '))
        }
      }
    })
  }

  // Private

  prefixKey(key, prefix) {
    if (prefix) {
      return `${prefix}-${key}`
    } else {
      return key
    }
  }
}
