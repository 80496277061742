import { Controller } from 'stimulus'
import { storeGet } from 'helpers/storage'
import EventBus from 'js-event-bus'; const eventBus = EventBus()

export default class extends Controller {
  static targets = [ 'bubble', 'label', 'step' ]

  STEPS = [{
    name: 'Breathe in',
    duration: 4000
  }, {
    name: 'Breathe out',
    duration: 4000
  }]

  connect() {
    this.isRunning = false
    this.labelTarget.parentElement.style.borderColor = this.color
    this.update()
    this.stop()
  }

  start () {
    if (this.animationFrameRequestId) { return }

    this.startCount = this.count
    this.lastTime = performance.now()
    this.animationFrameRequestId = requestAnimationFrame((ts) => this.loop(ts))

    eventBus.emit('breathingStarted')
    this.isRunning = true
  }

  stop () {
    if (this.animationFrameRequestId) {
      cancelAnimationFrame(this.animationFrameRequestId)
      eventBus.emit('breathingStopped')
      this.isRunning = false
    }

    this.animationFrameRequestId = null
    this.currentIndex = 0
    this.lastIndex = null
    this.bubbleTarget.style['transform'] = 'none'
    this.labelTarget.innerHTML = ''
  }

  loop (timestamp) {
    if (!this.isRunning) { return }

    var elapsedTime = timestamp
    var dt = elapsedTime - this.lastTime

    if (dt >= this.currentStep.duration * this.rate) {
      this.currentIndex++
      this.update()
      this.lastTime = elapsedTime
    } else if (this.currentIndex != this.lastIndex) {
      this.update()
      this.lastIndex = this.currentIndex
    }

    this.animationFrameRequestId = requestAnimationFrame((ts) => this.loop(ts))
  }

  update() {
    if (!this.isRunning) { return }

    if (this.count <= 0) {
      this.stop()
      this.labelTarget.innerHTML = `<div class="text-4xl">Great</div><div class="text-6xl">Job!</div><div class="mt-6"><a class="hoverable cursor-pointer" data-action="click->relax#stop">Go again?</a></div>`
    } else {
      if (this.currentIndex == 0) {
        this.labelTarget.innerHTML = `<div class="text-4xl opacity-80">${this.startCount - this.count + 1} / ${this.startCount}</div><div class="text-4xl">Breathe</div><div class="text-6xl">In</div>`
        this.bubbleTarget.style['transform'] = 'scale(1.2)'
      } else {
        this.labelTarget.innerHTML = `<div class="text-4xl opacity-80">${this.startCount - this.count + 1} / ${this.startCount}</div><div class="text-4xl">Breathe</div><div class="text-6xl">Out</div>`
        this.bubbleTarget.style['transform'] = 'none'
      }

      this.stepTargets.forEach((el, i) => {
        el.classList.toggle('bg-orange-200', this.currentIndex == i)
        el.innerHTML = `${this.STEPS[i].name}<br />${this.STEPS[i].duration * this.rate}ms`
      })
    }
  }

  get color() {
    return storeGet('profile', 'color')
  }

  get count() {
    return parseInt(this.data.get('count'))
  }

  set count(value) {
    this.data.set('count', value)
    this.update()
  }

  get currentStep() {
    return this.STEPS[this.currentIndex]
  }

  get currentIndex() {
    return this.data.get('index') || 0
  }

  set currentIndex(value) {
    if (value >= this.STEPS.length) {
      this.count--
      value = 0
    }
    this.data.set('index', value)
  }

  set rate(value) {
    return this.data.set('rate', value)
  }

  get rate() {
    return parseFloat(this.data.get('rate')) || 1.00
  }
}
