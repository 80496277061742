import { Controller } from "stimulus"
import { animateCSS } from "helpers/animation"

export default class extends Controller {
  static targets = ['container', 'content'];

  connect() {
    // The class we should toggle on the container
    this.toggleClass = this.data.get('class') || 'hidden';

    // The HTML for the background element
    this.backgroundHtml = this.data.get('backgroundHtml') || this._backgroundHTML();

    // The ID of the background to hide/remove
    this.backgroundId = this.data.get('backgroundId') || 'modal-background';

    // Let the user close the modal by clicking on the background
    this.allowBackgroundClose = (this.data.get('allowBackgroundClose') || 'true') === 'true';
    this.forceDisplay = (this.data.get('forceDisplay') || 'true') === 'true';

    if (this.forceDisplay) {
      this.open(null)
    }
  }

  disconnect() {
    this.close();
  }

  open(e) {
    e?.preventDefault();
    e?.target.blur();

    // Lock the scroll and save current scroll position
    this.lockScroll();

    // Unhide the modal
    this.containerTarget.classList.remove(this.toggleClass);

    // Insert the background
    if (!this.data.get("disable-backdrop")) {
      document.body.insertAdjacentHTML('beforeend', this.backgroundHtml);
      this.background = document.querySelector(`#${this.backgroundId}`);
    }

    if (e) {
      this.focusedElBeforeOpen = e.currentTarget
    }
    this.focusableEls = Array.prototype.slice.call(this.element.querySelectorAll('a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]):not(.sm\\:hidden):not(.invisible), [tabindex="0"]'));

    animateCSS({ element: this.containerTarget, enabled: !this.forceDisplay, classes: ['zoomIn'] })

    this.didOpen()
  }

  focus(index) {
    this.focusableEls[index].focus()
  }

  close(e) {
    e?.preventDefault();

    // TODO: Temp disabled these animations since they cause issues when going back...possibly due to Turbolinks
    // animateCSS({ element: this.background, enabled: false, classes: ['fadeOut'], callback: function() {
      // Remove the background
      this.background?.remove()
    // }})

    // animateCSS({ element: this.containerTarget, enabled: false, classes: ['zoomOut'], callback: function() {
      // Unlock the scroll and restore previous scroll position
      this.unlockScroll();

      // Hide the modal
      this.containerTarget.classList.add(this.toggleClass);

      this.focusedElBeforeOpen?.focus()
    // } })

    this.didClose()
  }

  async didOpen() {
    this.contentTarget.querySelectorAll('[data-controller]').forEach(el => {
      if (el.dataset.controller != 'button') {
        this.getController(el, el.dataset.controller)?.didOpenModal?.()
      }
    })
  }

  async didClose() {
    this.contentTarget.querySelectorAll('[data-controller]').forEach(el => {
      if (el.dataset.controller != 'button') {
        this.getController(el, el.dataset.controller)?.didCloseModal?.()
      }
    })
  }

  closeBackground(e) {
    if (this.allowBackgroundClose && e.target === this.containerTarget) {
      this.close(e);
    }
  }

  handleKeyDown(e) {
    if (this.isVisible) {
      if (e.keyCode === 27 && !this.containerTarget.classList.contains(this.toggleClass)) {
        this.close(e);
      } else if (e.keyCode === 9) {
        if (this.focusableEls.length === 1) {
          e.preventDefault();
        } else if (e.shiftKey) {
          this.handleTab(e, false);
        } else {
          this.handleTab(e, true);
        }
      }
    }
  }

  handleTab(e, isForward) {
    var firstEl = this.focusableEls[0]
    var lastEl = this.focusableEls[this.focusableEls.length - 1]

    if (isForward && document.activeElement == lastEl) {
      e.preventDefault();
      firstEl.focus()
    } else if (!isForward && document.activeElement == firstEl) {
      e.preventDefault();
      lastEl.focus()
    }

    // if (!this.focusableEls.includes(document.activeElement)) {
    //   e.preventDefault();
    //   if (isForward) {
    //     this.focusableEls[0].focus()
    //   } else {
    //     this.focusableEls[this.focusableEls.length - 1].focus();
    //   }
    // }
  }

  _backgroundHTML() {
    var bgOpacity = this.data.get('bgOpacity') || 0.9
    return "<div tabindex=\"-1\" id=\"modal-background\" class=\"modal-background\" style=\"opacity: " + bgOpacity + ";\"></div>";
  }

  lockScroll() {
    // Add right padding to the body so the page doesn't shift
    // when we disable scrolling
    const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
    document.body.style.paddingRight = `${scrollbarWidth}px`;

    // Save the scroll position
    this.saveScrollPosition();

    // Add classes to body to fix its position
    document.body.classList.add('fixed', 'inset-x-0', 'overflow-hidden');

    // Add negative top position in order for body to stay in place
    document.body.style.top = `-${this.scrollPosition}px`;
  }

  unlockScroll() {
    // Remove tweaks for scrollbar
    document.body.style.paddingRight = null;

    // Remove classes from body to unfix position
    document.body.classList.remove('fixed', 'inset-x-0', 'overflow-hidden');

    // Restore the scroll position of the body before it got locked
    this.restoreScrollPosition();

    // Remove the negative top inline style from body
    document.body.style.top = null;
  }

  saveScrollPosition() {
    this.scrollPosition = window.pageYOffset || document.body.scrollTop;
  }

  restoreScrollPosition() {
    document.documentElement.scrollTop = this.scrollPosition;
  }

  getController(el, name) {
    return this.application.getControllerForElementAndIdentifier(
      el,
      name
    )
  }

  get isVisible() {
    return !this.containerTarget.classList.contains('hidden')
  }
}
