import { Controller } from "stimulus"
import { storeGet, storeGetInt, storeSet } from 'helpers/storage'
import EventBus from 'js-event-bus'; const eventBus = EventBus()

export default class extends Controller {
  static targets = ['nameInput', 'colorInput' ]

  connect() {
    this.nameInputTarget.value = this.name
    if (this.color) {
      this.colorInputTarget.value = this.color
    }

    if (this.avatarId) {
      this.element.querySelector(`input[name=avatar_id][value="${this.avatarId}"]`).setAttribute('checked', true)
    }
  }

  nameChanged(e) {
    if (this.name != e.target.value) {
      document.getElementById('profile-name').innerHTML = this.name = e.target.value
      eventBus.emit('profileUpdated')
    }
  }

  avatarSelected(e) {
    if (this.avatarId != e.target.value) {
      this.avatarId = e.target.value
      document.getElementById('profile-avatar').firstElementChild.src = e.target.nextElementSibling.src
      eventBus.emit('profileUpdated')
    }
  }

  colorChanged(e) {
    if (this.color != e.target.value) {
      document.getElementById('profile-avatar').style.backgroundColor = this.color = e.target.value
      eventBus.emit('profileUpdated')
    }
  }

  get avatarId() {
    return storeGetInt('profile', 'avatar-id')
  }

  set avatarId(value) {
    storeSet(value, 'profile', 'avatar-id')
  }

  get name() {
    return storeGet('profile', 'name')
  }

  set name(value) {
    storeSet(value, 'profile', 'name')
  }

  get color() {
    return storeGet('profile', 'color')
  }

  set color(value) {
    storeSet(value, 'profile', 'color')
  }
}
