import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = [ 'input', 'countValue' ]

  connect() {
    this.maxValue = this.data.get('max-value') || '50'
    this.inputTarget.maxLength = this.maxValue
    this.update()
  }

  update() {
    var count = this.inputTarget.value.length

    if (count > 0) {
      this.setMessage(`${count} / ${this.maxValue}`)
    } else {
      this.setMessage('')
    }
  }

  setMessage(message) {
    this.countValueTarget.textContent = message
  }
}
