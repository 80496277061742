import { Controller } from "stimulus"
import { showAlert } from "helpers/alert"
import { animateCSS } from "helpers/animation"
import { Confetti } from 'confetti'
import { isPortrait } from "helpers/platform"
import { storeGet, storeGetInt } from 'helpers/storage'
import { avatarAssetPath } from 'helpers/assets'
import Sound from 'helpers/sound'

// Abstract
export default class extends Controller {
  static targets = ['board']

  connect() {
    this.soundEffects = {}
    let sfx = JSON.parse(this.element.dataset['gameSfx'])
    if (sfx) {
      Object.entries(sfx).forEach(arr => {
        this.soundEffects[arr[0]] = new Sound(arr[1], this.element)
      })
    }
    this.setup()
    this.layout()
  }

  disconnect() {
    this.teardown()
  }

  setup() {
    if (this.videoUrl && !this.videoEl) {
      var tapToContinueButton = document.createElement('button')
      tapToContinueButton.style.zIndex = 100000
      tapToContinueButton.className = 'full-screen hidden flex flex-col justify-center items-center font-bold text-white text-4xl'

      let continueButtonHtml = ``

      if (this.avatarId) {
        continueButtonHtml = `<img src=${avatarAssetPath(this.avatarId)} class="w-48 h-48 rounded-full animate__animated animate__bounceInDown animate__slow" style="background-color: ${this.color};" />`
      }

      if (this.profileName) {
        continueButtonHtml += `<div class="animate__animated animate__bounceInUp animate__slow">Great Job ${this.profileName}!`
      } else {
        continueButtonHtml += `<div class="animate__animated animate__bounceInUp animate__slow">Great Job!`
      }

      continueButtonHtml += `<br /><span class="animate__animated animate__fadeInUp animate__delay-1s" style="border-bottom: 0.5rem solid ${this.color};">Tap to Continue</span></div>`

      tapToContinueButton.innerHTML = continueButtonHtml

      tapToContinueButton.setAttribute('data-action', `click->${this.identifier}#didTapToContinue`)
      this.element.appendChild(tapToContinueButton)

      var videoEl = document.createElement('video');
      videoEl.src = this.videoUrl;
      videoEl.className = 'full-screen hidden'
      videoEl.style.zIndex = 100000
      this.element.appendChild(videoEl)

      videoEl.addEventListener('pause', (e) => {
        this.videoFinished(e)
      })
      videoEl.addEventListener('ended', (e) => {
        this.videoFinished(e)
      })

      this.tapToContinueButton = tapToContinueButton
      this.videoEl = videoEl
    }
  }

  layout() {}

  teardown() {
    this.startTime = null

    if (this.videoEl) {
      this.videoEl.currentTime = 0
      this.videoEl.classList.add('hidden')
    }

    Confetti.remove()
  }

  reset() {
    this.teardown()
    this.setup()
    this.layout()
    this.won = null
  }

  // Overridden
  get winCondition() {
    return false
  }

  checkWin(delay = 0) {
    if (!this.won && this.winCondition) {
      this.won = true

      if (this.startTime) {
        let secondsPlayed = Math.round((new Date().getTime() - this.startTime) / 1000.0)
        this.data.set('time', secondsPlayed)
      }

      setTimeout(() => {
        if (this.videoEl) {
          this.tapToContinueButton.classList.remove('hidden')
          animateCSS({ element: this.tapToContinueButton, classes: ['fadeIn']})
        }

        Confetti.start(3000, { color: this.color})
      }, delay)
    }
  }

  startTimer() {
    if (!this.startTime) {
      this.startTime = new Date().getTime()
    }
  }

  didTapToContinue(e) {
    this.tapToContinueButton.classList.add('hidden')
    this.videoEl.classList.remove('hidden')
    this.videoEl.play()
    Confetti.remove()
  }

  animateEmoji({x, y}) {
    if (this.emojis) {
      let emojiEl = document.createElement('div')
      emojiEl.classList.add('game-emoji')

      emojiEl.style.left = x + 'px'
      emojiEl.style.top = y + 'px'
      this.bumpZ(emojiEl)

      emojiEl.innerHTML = this.nextEmoji

      this.boardTarget.appendChild(emojiEl)

      animateCSS({ element: emojiEl, classes: ['fadeOutUp'], callback: function(el) {
        emojiEl.remove()
      }})
    }
  }

  bumpZ(el) {
    if (!this.zIndexCounter) { this.zIndexCounter = this.currentPuzzlePieces.length + 1 }
    if (el.style.zIndex != this.zIndexCounter) {
      el.style.zIndex = ++this.zIndexCounter
    }
  }

  showWinAlert() {
    showAlert({
      title: `You did it!`,
      text: `Finished in ${this.data.get('time')} seconds`,
      icon: 'success',
      confirmButtonText: 'Play Again',
      cancelButtonText: 'Go Back',
      showCancelButton: true,
      backdrop: 'black'
    }).then((result) => {
      if (result.value) {
        this.reset()
      } else {
        window.history.back()
      }
    })
  }

  videoFinished(e) {
    if (this.videoEl.classList.contains('hidden')) { return }

    if (e.target.webkitDisplayingFullscreen) {
      e.target.webkitExitFullscreen()
    }
    this.videoEl.pause()
    this.showWinAlert()
    setTimeout(() => {
      this.videoEl.classList.add('hidden')
    }, 500)
  }

  get isPortrait() {
    return isPortrait(this.element)
  }

  get avatarId() {
    return storeGetInt('profile', 'avatar-id')
  }

  get color() {
    return storeGet('profile', 'color')
  }

  get profileName() {
    return storeGet('profile', 'name')
  }

  get level() {
    return parseInt(storeGet('games', 'diff') || this.element.dataset['gameLevel'])
  }

  get nextEmoji() {
    if (!this.emojis) { return null }

    this._emojiCounter = this._emojiCounter + 1 || 0
    if (this._emojiCounter > this.emojis.length - 1) { this._emojiCounter = 0 }
    return this.emojis[this._emojiCounter]
  }

  get videoUrl() {
    if (this.isPortrait) {
      return this.element.dataset['gamePortraitVideoUrl'] || this.element.dataset['gameVideoUrl']
    } else {
      return this.element.dataset['gameVideoUrl']
    }
  }
}
