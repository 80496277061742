export default class Sound {
  constructor(src, parentElement) {
    this.sound = document.createElement("audio")
    this.sound.src = src
    this.sound.setAttribute("preload", "auto")
    this.sound.setAttribute("controls", "none")
    this.sound.style.display = "none"

    parentElement.appendChild(this.sound)
  }

  play() {
    this.sound.play()
  }

  pause() {
    this.sound.pause()
  }

  stop() {
    this.sound.pause()
    this.sound.currentTime = 0
  }
}
