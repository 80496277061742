import { AppController } from './app_controller'
import { presence } from "helpers/array"
import { storeMatches, storeSet, storeGet, storeGetInt, storeGetJSON, storeRemove } from 'helpers/storage'
import Rails from "@rails/ujs";
import EventBus from 'js-event-bus'; const eventBus = EventBus()

export default class extends AppController {
  static targets = [ 'download', 'tooltip' ]

  connect() {
    eventBus.on('profileUpdated', this.profileUpdated)

    // Only enable download button if most of profile filled in
    let canDownload = storeMatches('profile').length > 4

    this.tooltipTarget.classList.toggle('hidden', canDownload)
    this.downloadTarget.classList.toggle('cursor-not-allowed', !canDownload)
    this.downloadTarget.disabled = canDownload ? null : 'disabled'
  }

  disconnect() {
    eventBus.detach('profileUpdated')
  }

  delete(e) {
    if (window.confirm('You will lose your set interests, sensory preferences, and more.\nAre you sure?')) {
      window.localStorage.clear()
      window.location.reload(true)
    }
  }

  download(e) {
    Rails.ajax({
      type: "POST",
      url: `${this.downloadUrl}?pdf_id=${this.pdfId}&data=${this.profileData()}`,
      success: (data, _status, xhr) => {
        if (data?.data?.pdf_id) {
          storeSet(data.data.pdf_id, 'profile', 'pdf-id')
        }
        if (data?.data?.download_url) {
          window.location = data.data.download_url
        }
      }
    })
  }

  profileData() {
    let data = {}

    if (this.name) { data['name'] = this.name }
    if (this.color) { data['color'] = this.color.replace('#', '') }
    if (this.avatarId) { data['avatar_id'] = this.avatarId }
    if (this.interests) { data['interests'] = this.interests }
    if (this.calms) { data['calms'] = this.calms }
    if (this.communicationIds) { data['communication_ids'] = this.communicationIds }
    if (this.sensory) { data['sensory'] = this.sensory }

    return JSON.stringify(data)
  }

  profileUpdated() {
    storeRemove('profile', 'pdf-id')
  }

  get pdfId() {
    return storeGet('profile', 'pdf-id')
  }

  get avatarId() {
    return storeGetInt('profile', 'avatar-id')
  }

  get name() {
    return storeGet('profile', 'name')
  }

  get color() {
    return storeGet('profile', 'color')
  }

  get communicationIds() {
    return presence(storeGetJSON('profile', 'communication_ids'))
  }

  get interests() {
    return presence(storeGetJSON('profile', 'interests'))
  }

  get calms() {
    return presence(storeGetJSON('profile', 'calms'))
  }

  get sensory() {
    return storeGetJSON('profile', 'sensory')
  }

  get downloadUrl() {
    return this.data.get('downloadUrl')
  }
}
