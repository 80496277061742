// Usage:
//  import { isMobile, isIos } from "helpers/platform"

const { userAgent } = window.navigator

export const isIos = /iPhone|iPad|iPod/.test(userAgent)
export const isAndroid = /Android/.test(userAgent)
export const isMobile = isIos || isAndroid
export const isMac = /Mac/.test(userAgent)

export const isIosApp = /(INFAcc) iOS/.test(userAgent)
export const isAndroidApp = /INFAcc Android/.test(userAgent)
export const isMacApp = /INFAcc macOS/.test(userAgent)
export const isNativeApp = isIosApp || isAndroidApp || isMacApp

export function isPortrait(el) {
  return el.clientHeight > el.clientWidth
}
