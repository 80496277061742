import { Controller } from "stimulus"
import { storeGet, storeSet } from 'helpers/storage'

export default class extends Controller {
  static targets = [ 'autoplayCheckbox', 'img', 'text', 'vo', 'indicator' ]

  connect() {
    this.update()

    this.isPlaying = false
    this.dragStartX = null
    this.autoplayCheckboxTarget.checked = !this.disableAutoplay
  }

  didCloseModal() {
    this.isPlaying = false
    this.voEnded()
  }

  jump(e) {
    const element = e.currentTarget

    if (this.index != element.dataset.slideIndex) {
      this.index = element.dataset.slideIndex
    }
  }

  onKeyPress(e) {
    if (this.isVisible) {
      if (e.keyCode == 37) {        // Left arrow
        this.index--
      } else if (e.keyCode == 39) { // Right Arrow
        this.index++
      // } else if (e.keyCode == 32) { // Space
      //   this.togglePlayback(null)
      }
    }
  }

  startDrag(e) {
    this.dragStartX = e.screenX
  }

  endDrag(e) {
    let delta = this.dragStartX - e.screenX
    if (delta > 100) {
      this.showNextSlide()
    } else if (delta < -100) {
      this.showPrevSlide()
    }
    this.dragStartX = null
  }

  fullscreen(e) {
    const element = e.currentTarget
    // TODO: Use higher quality img for full screen
    window.open(element.src, '_blank');
  }

  showPrevSlide() {
    this.index--
  }

  showNextSlide() {
    this.index++
  }

  stopVO() {
    this.voTargets.forEach((el, i) => {
      el.pause()
      el.currentTime = 0
    })
  }

  update() {
    const currentIndex = this.index
    this.imgTargets.forEach((el, i) => {
      el.classList.toggle("hidden", currentIndex != i)
    })

    this.textTargets.forEach((el, i) => {
      el.classList.toggle("hidden", currentIndex != i)
    })

    this.indicatorTargets.forEach((el, i) => {
      el.classList.toggle("bg-primary", currentIndex == i)
    })
  }

  toggleAutoplay(e) {
    this.disableAutoplay = !e.target.checked
  }

  togglePlayback(e) {
    this.isPlaying = !this.isPlaying

    let currentAudio = this.voTargets[this.index]
    if (this.isPlaying) {
      currentAudio?.play()
    } else {
      currentAudio?.pause()
    }
  }

  toggleVO(e) {
    let buttonController = this.application.getControllerForElementAndIdentifier(
      this.playbackButton,
      "button"
    )

    if (e?.type == 'play') {
      buttonController.state = 'playing'
    } else {
      buttonController.state = 'default'
      this.isPlaying = false
    }
  }

  voEnded(e) {
    this.stopVO()
    this.toggleVO(e)

    if (e && this.autoplayCheckboxTarget.checked && this.index < this.slideCount - 1) {
      this.showNextSlide()

      setTimeout(() => {
        if (this.isVisible) {
          this.togglePlayback()
        }
      }, 500)
    }
  }

  get playbackButton() {
    return this.element.getElementsByClassName('playback-button')[0].firstElementChild
  }

  get slideCount() {
    return this.indicatorTargets.length
  }

  get disableAutoplay() {
    return storeGet(this.identifier, 'autoplay') == "false"
  }

  set disableAutoplay(value) {
    storeSet(!value, this.identifier, 'autoplay')
  }

  get isVisible() {
    return this.element.offsetParent
  }

  get index() {
    return parseInt(this.data.get("index") || 0)
  }

  set index(value) {
    if (this.isPlaying) {
      this.voEnded({})
    }

    if (value < 0) {
      this.data.set("index", this.slideCount - 1)
    } else if (value >= this.slideCount) {
      this.data.set("index", 0)
    } else {
      this.data.set("index", value)
    }
    this.update()
  }
}
