import { AppController } from './app_controller'

export default class extends AppController {
  showGuide(e) {
    let modalController = this.showModal('guide_slideshow', e)
    modalController.focus(2)
  }

  showTool(e) {
    let modalController = this.getModal('dental_tool')
    let toolInfoEl = modalController.contentTarget.firstElementChild

    let toolInfoController = this.getController(toolInfoEl, "tool-info")
    toolInfoController.setTool({
      name: e.currentTarget.dataset.toolInfoName,
      bg: e.currentTarget.dataset.toolInfoBg,
      feelsLike: e.currentTarget.dataset.toolInfoFeelsLike,
      usedFor: e.currentTarget.dataset.toolInfoUsedFor,
    })

    modalController.open(e)
  }
}
