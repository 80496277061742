import { Controller } from 'stimulus'
import { paramIsNull } from 'helpers/params'
import { storeIsNull, storeLength, storeMatches } from 'helpers/storage'

export default class extends Controller {
  initialize() {
    this.element.classList.add('hidden')
  }

  connect() {
    if (this.shouldShow()) {
      this.element.classList.remove('hidden')
    }
  }

  shouldShow() {
    if (this.validation == "presence") {
      if (this.source == 'store') {
        return !storeIsNull(this.key)
      } else if (this.source == 'param') {
        return !paramIsNull(this.key)
      }
    } else if (this.validation == "null") {
      if (this.source == 'store') {
        return storeIsNull(this.key)
      } else if (this.source == 'param') {
        return paramIsNull(this.key)
      }
    } else if (this.validation == "any") {
      return storeMatches(this.key).length > 0
    } else if (this.validation == "store") {
      return storeLength() > 0
    }

    return false
  }

  get key() {
    return this.data.get('key')
  }

  get source() {
    return this.data.get('source') || 'store'
  }

  get validation() {
    return this.data.get('validation') || 'presence' // Default to 'presence'
  }
}
