import { Controller } from "stimulus"
import { storeGet, storeSet } from 'helpers/storage'

export default class extends Controller {
  connect() {
    if (this.emotionKey) {
      this.element.querySelector(`input[name=emotions][value="${this.emotionKey}"]`).setAttribute('checked', true)
    }
  }

  tappedEmotion(e) {
    this.emotionKey = e.target.value
    this.updateDisplay()
    this.closeModal()
  }

  closeModal() {
    this.application.getControllerForElementAndIdentifier(
      document.querySelector('#emotion_picker'),
      'modal'
    ).close(null)
  }

  updateDisplay() {
    this.application.getControllerForElementAndIdentifier(
      document.querySelector('[data-modal="emotion_picker"]'),
      'emotion-display'
    ).update()
  }

  set emotionKey(value) {
    return storeSet(value, 'emotion-picker')
  }

  get emotionKey() {
    return storeGet('emotion-picker')
  }
}
