import { Controller } from "stimulus"

export default class extends Controller {

  connect() {
    console.log('Hello Preview')
  }

  showPlaceholderAlert(event) {
    alert('This is a placeholder')
  }
}
