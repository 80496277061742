import { Controller } from "stimulus"
import { animateCSS } from "helpers/animation"
import { storeGetJSON, storeSetJSON } from 'helpers/storage'
import EventBus from 'js-event-bus'; const eventBus = EventBus()

export default class extends Controller {
  static targets = [ 'hint', 'ideas', 'panel', 'list', 'ideaList', 'ideaTitle' ]

  connect() {
    this.hintTarget.style['display'] = 'none'
    this.ideasTarget.style['display'] = null

    this.trashSVG = JSON.parse(this.data.get('trash-svg'))
    // this.data.delete('trash-svg') // These break with Turbolinks...disabling for now

    this.ideas = JSON.parse(this.data.get('ideas'))
    // this.data.delete('ideas') // These break with Turbolinks...disabling for now

    this.storeFetchItems()

    this.clearList(this.listTarget)
    this.clearList(this.ideaListTarget)

    this.items.forEach(item => {
      this.appendItem({
        name: item
      })
    })

    this.ideas?.forEach(idea => {
      if (!this.items.includes(idea)) {
        this.appendIdea({
          name: idea
        })
      }
    })

    this.hasIdeas = this.ideas != null

    this.update()
  }

  update() {
    this.panelTarget.style['display'] = this.items.length == 0 ? 'none' : null

    if (this.hasIdeas) {
      if (this.ideaListTarget.childElementCount == 0) {
        this.ideaTitleTarget.innerHTML = `<div class="my-2">All out of ideas! Try adding your own.</div>`
      } else {
        this.ideaTitleTarget.innerHTML = "Ideas:"
      }
    } else {
      this.ideasTarget.style['display'] = 'none'
    }
  }

  inputChanged(e) {
    if (e.currentTarget.value.trim().length == 0) {
      this.hintTarget.style['display'] = 'none'
      if (this.hasIdeas) {
        this.ideasTarget.style['display'] = null
      }
    } else {
      this.hintTarget.style['display'] = null
      if (this.hasIdeas) {
        this.ideasTarget.style['display'] = 'none'
      }

      this.hintTarget.innerHTML = `Tap Enter to add <b>${e.currentTarget.value.trim()}</b>.`

      if (e.keyCode == 13) { // Enter
        e.preventDefault()
        this.submit(e)
        return false;
      }
    }
  }

  addIdea(e) {
    this.items.push(e.target.innerText)
    this.storeCommitItems()

    this.appendItem({
      name: e.target.innerText
    })
    e.target.parentElement.remove()

    this.update()
  }

  submit(e) {
    if (this.items.includes(e.target.value)) {
      let existingLi = this.listTarget.querySelector(`li[data-name="${e.target.value}"`)
      existingLi.style.setProperty('--animate-duration', '1s');
      existingLi.classList.add('border-red')
      animateCSS({ element: existingLi, classes: ['headShake'], callback: function(el) {
        el.classList.remove('border-red')
      }})
      return
    }

    this.items.push(e.target.value)
    this.storeCommitItems()

    this.appendItem({
      name: e.target.value
    })

    e.target.value = null
    this.update()
  }

  delete(e) {
    this.items = this.items.filter(it => it != e.currentTarget.value)
    this.storeCommitItems()

    e.currentTarget.parentElement.remove()

    if (this.ideas.includes(e.currentTarget.value)) {
      this.appendIdea({
        name: e.currentTarget.value
      })
    }
    this.update()
  }

  // private

  throwMissingData(key) {
    throw(`'${key}' not set`)
  }

  get eventName() {
    return this.data.get('event')
  }

  get storeKey() {
    return this.data.get('storeKey') || this.throwMissingData('store-key')
  }

  storeCommitItems() {
    storeSetJSON(this.items?.sort(), this.storeKey)
    if (this.eventName) {
      eventBus.emit(this.eventName)
    }
  }

  storeFetchItems() {
    this.items = [...new Set(storeGetJSON(this.storeKey) || [])]?.sort()
  }

  clearList(listTarget) {
    listTarget.innerHTML = null
  }

  appendItem(item) {
    this.listTarget.insertAdjacentHTML('beforeend', this.html(item))
  }

  appendIdea(item) {
    this.ideaListTarget.insertAdjacentHTML('beforeend', this.ideaHtml(item))
  }

  html(item) {
    return `
      <li class="my-4 p-4 rounded-md bg-white dark:bg-dark-70 w-full h-16 px-2 flex justify-between items-center border-2 border-solid border-transparent" data-name="${item.name}">
        <div class="m-4">${item.name}</div>
        <button aria-label="Delete ${item.name}" title="Delete ${item.name}" class="print:hidden p-4 hoverable rounded-full " data-action="click->list#delete" name="item" value="${item.name}">
          ${this.trashSVG}
        </button>
      </li>
    `
  }

  ideaHtml(item) {
    return `
      <li>
        <button data-action="click->list#addIdea" class="hoverable px-2 py-1 btn-secondary rounded-md mr-1 mb-1 mt-1 flex items-center" aria-label="Add ${item.name}" title="Add ${item.name}">
          ${item.name}
        </button>
      </li>
    `
  }
}
