import { Controller } from "stimulus"
import { animateCSS } from "helpers/animation"

export default class extends Controller {
  static targets = ['overlay', 'body']

  connect() {
    this.bodyTarget.style.opacity = 0
  }

  loaded(e) {
    animateCSS({ element: this.overlayTarget, classes: ['fadeOut'], callback: function(el) {
      // Remove the background
      el.remove()
    }})

    animateCSS({ element: this.bodyTarget, classes: ['fadeIn'], callback: function(el) {
      el.style.opacity = 1.0
    }})
  }
}
