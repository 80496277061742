import GameController from 'controllers/game_controller'
import { sample } from "helpers/array"
import { storeGet, storeSet } from 'helpers/storage'

export default class extends GameController {
  static targets = ['bg', 'cell', 'template']

  connect() {
    this.cardData = JSON.parse(this.data.get('cards'))
    this.data.delete('cards')

    this.cardBg = this.data.get('cardBg')

    super.connect()
  }

  setup() {
    super.setup()

    let totalCardCount = this.expectedCols * this.expectedRows
    let cardCount = totalCardCount
    let currentCardData = [...this.cardData.sort(function () { return 0.5 - Math.random() })]

    let cardsForThisRound = currentCardData.splice(0, cardCount / 2)
    cardsForThisRound = cardsForThisRound.concat(cardsForThisRound).sort(function () { return 0.5 - Math.random() })

    let backgroundImageCard = sample(currentCardData.filter(card => card.id != this.lastBackgroundId))
    this.lastBackgroundId = backgroundImageCard.id

    this.bgTarget.style.backgroundImage = `url('${backgroundImageCard.bg}')`

    while( cardCount-- ) {
      // Clone the 'template' for 'cell'
      let cellEl = this.templateTarget.firstElementChild.cloneNode(true)
      let cellCardData = cardsForThisRound[cardCount]
      cellEl.dataset['target'] = 'card-matching.cell'

      let cardEl = cellEl.firstElementChild
      cardEl.dataset['id'] = cellCardData.id
      cardEl.ariaLabel = `Card Number ${totalCardCount - cardCount}`

      let cardTopEl = cardEl.firstElementChild.firstElementChild
      let cardBottomEl = cardEl.firstElementChild.lastElementChild

      cardTopEl.style.backgroundImage = "url('" + this.cardBg + "')"
      // cardTopEl.innerHTML = cellCardData.id
      cardBottomEl.style.backgroundImage = "url('" + cellCardData.bg + "')"

      this.boardTarget.appendChild(cellEl)
    }
  }

  teardown() {
    this.cellTargets.forEach(el => el.remove())

    super.teardown()
  }

  layout(e) {
    super.layout()

    let nCol = this.isPortrait ? this.expectedCols : this.expectedRows
    let nRow = this.isPortrait ? this.expectedRows : this.expectedCols

    let width = this.element.clientWidth
    let height = this.element.clientHeight
    let smallerDimen = Math.min(width, height)
    let largerDimen = Math.max(width, height)


    let cellSize = Math.floor(smallerDimen / Math.min(nCol, nRow))
    if (cellSize * Math.max(nCol, nRow) > largerDimen) {
      cellSize = Math.floor(largerDimen / Math.max(nCol, nRow))
    }

    this.boardTarget.style.width = `${cellSize * nCol}px`
    this.boardTarget.style.height = `${cellSize * nRow}px`

    this.cellTargets.forEach((el, i) => {
      el.style.height = `${cellSize}px`
      el.style.width = `${cellSize}px`
    })
  }

  get winCondition() {
    return this.element.querySelectorAll("#card-matching-board .card-matching-card").length == 0
  }

  flip(e) {
    let selectedEl = e.currentTarget
    if (selectedEl.dataset['flipped'] || this.flippedCards.length == 2) { return null; }

    this.startTimer()

    this.soundEffects[`flip_${this.flippedCards.length + 1}`].play()

    selectedEl.dataset['flipped'] = true
    selectedEl.setAttribute('tabindex', "-1")

    if (this.flippedCards.length == 2) {
      this.boardTarget.classList.add('pointer-events-none')
      setTimeout(() => {
        // Check Match
        if (this.flippedCards[0].dataset.id == this.flippedCards[1].dataset.id) {
          this.flippedCards[0].remove()
          this.flippedCards[0].remove()
        } else {
          this.flippedCards.forEach(el => {
            this.unflip(el)
          })
        }
        this.boardTarget.classList.remove('pointer-events-none')

        this.checkWin(500)
      }, 1000)
    }
  }

  unflip(cardEl) {
    cardEl.removeAttribute('data-flipped')
    cardEl.removeAttribute('tabindex')
    cardEl.blur()
  }

  get expectedCols() {
    switch(this.level) {
      case 0: return 1
      case 1: return 2
      default: return 3
    }
  }

  get expectedRows() {
    switch(this.level) {
      case 0: return 2
      case 1: return 3
      default: return 4
    }
  }

  get flippedCards() {
    return this.element.querySelectorAll("#card-matching-board .card-matching-card[data-flipped='true']")
  }

  get lastBackgroundId() {
    return parseInt(storeGet(this.identifier, 'last-bg'))
  }

  set lastBackgroundId(value) {
    storeSet(value, this.identifier, 'last-bg')
  }
}
