import GameController from 'controllers/game_controller'
const Draggabilly = require('draggabilly')
import { sample } from "helpers/array"
import { animateCSS } from "helpers/animation"
import { didCollide, getFunEmojis } from "helpers/game"
import { pictureTag } from "helpers/html"
import { storeGet, storeSet } from 'helpers/storage'

export default class extends GameController {
  static targets = ['workspace', 'piece']

  connect() {
    this.puzzleData = JSON.parse(this.data.get('puzzles'))
    this.data.delete('puzzles')

    this.emojis = getFunEmojis('🦷')

    super.connect()
  }

  setup() {
    super.setup()

    if (this.color) {
      this.boardTarget.style.border = `0.15rem dashed ${this.color}`
    }

    let randomPuzzle = sample(this.puzzleData.filter(puzzle => puzzle.id != this.lastPuzzleId))
    this.lastPuzzleId = randomPuzzle.id
    this.currentPuzzlePieces = randomPuzzle.pieces.filter(piece => piece.level == this.level)

    for (var i = 0; i < this.currentPuzzlePieces.length; i++) {
      let el = pictureTag({ imgSizes: this.currentPuzzlePieces[i].img_sizes })
      this.boardTarget.appendChild(el)

      let draggablePiece = el.lastElementChild
      draggablePiece.className = `jigsaw-puzzle-piece jigsaw-puzzle-piece-${this.currentPuzzlePieces.length}-${this.currentPuzzlePieces[i].position + 1}`
      draggablePiece.setAttribute('data-target', 'jigsaw-puzzle.piece')
      draggablePiece.dataset.id = this.currentPuzzlePieces[i].id
      draggablePiece.dataset.position = this.currentPuzzlePieces[i].position
      draggablePiece.style.zIndex = i + 1

      let draggie = new Draggabilly(draggablePiece, { containment: 'body' })
      draggie.on('pointerDown', e => this.onTouchStart(e, draggie))
      draggie.on('dragStart', e => this.onDragStart(e, draggie))
      draggie.on('dragEnd', e => this.onDragEnd(e, draggie))
    }
  }

  teardown() {
    this.pieceTargets.forEach(el => el.remove())

    super.teardown()
  }

  layout(e) {
    super.layout()

    let width = this.element.clientWidth
    let height = this.element.clientHeight
    let smallerDimen = Math.min(width, height)
    let largerDimen = Math.max(width, height)
    let workspaceDimen = largerDimen * 0.333
    smallerDimen = Math.min(largerDimen - workspaceDimen, smallerDimen)

    let sideCount = Math.sqrt(this.currentPuzzlePieces.length)

    const pieceDimen = Math.floor(smallerDimen / (sideCount * 1.0))

    this.element.classList.toggle('flex-col', this.isPortrait)
    this.element.classList.toggle('flex-row', !this.isPortrait)

    if (this.isPortrait) {
      this.workspaceTarget.style.width = '100%'
      this.workspaceTarget.style.height = workspaceDimen + 'px'
    } else {
      this.workspaceTarget.style.width = workspaceDimen + 'px'
      this.workspaceTarget.style.height = '100%'
    }

    this.boardTarget.style.width = `${smallerDimen}px`
    this.boardTarget.style.height = `${smallerDimen}px`

    let workspaceOffsetLeft = this.workspaceTarget.offsetLeft
    let workspaceOffsetTop = this.workspaceTarget.offsetTop

    let startX = workspaceOffsetLeft - this.boardTarget.offsetLeft
    let endX = workspaceOffsetLeft - this.boardTarget.offsetLeft + this.workspaceTarget.clientWidth - (pieceDimen * 1.5)

    let startY = workspaceOffsetTop - this.boardTarget.offsetTop
    let endY = workspaceOffsetTop - this.boardTarget.offsetTop + this.workspaceTarget.clientHeight - (pieceDimen)

    let rowCounter = 0
    let colCounter = 0

    this.pieceTargets.forEach((el, i) => {
      el.dataset.x = colCounter * pieceDimen
      el.dataset.y = rowCounter * pieceDimen

      if (!el.classList.contains('locked')) {
        el.style.left = `${this.randomNum(startX, endX)}px`
        el.style.top = `${this.randomNum(startY, endY)}px`
      }

      colCounter += 1
      if (colCounter >= sideCount) {
        colCounter = 0
        rowCounter += 1
      }
    })
  }

  // Override
  get winCondition() {
    return this.pieceTargets.length == this.element.querySelectorAll('.jigsaw-puzzle-piece.locked').length && this.element.querySelectorAll('.jigsaw-puzzle-emoji').length == 0
  }

  randomNum(min, max) {
    return Math.floor(Math.random() * (max - min)) + min
  }

  lockPiece(el) {
    this.soundEffects['piece_placed'].play()
    el.classList.add('locked')
    el.draggable = false

    el.style.setProperty('--animate-duration', '0.3s');

    animateCSS({ element: el, classes: ['pulse'], callback: function(el) {
      el.style.zIndex = 1
    }})

    let xOffset = (Math.random() * Math.min(el.clientHeight, el.clientWidth) * 0.25) * (Math.random() > 0.5 ? 1.0 : -1.0)

    this.animateEmoji({
      x: el.offsetLeft + (el.clientWidth / 2.0) + xOffset,
      y: el.offsetTop + (el.clientHeight / 2.0)
    })

    this.checkWin(2000)
  }

  onTouchStart(e, draggie) {
    this.startTimer()
    this.bumpZ(e.target)
  }

  onDragStart(e, draggie) {
    this.startTimer()
    this.bumpZ(e.target)
  }

  onDragEnd(e, draggie) {
    const pieceEl = draggie.element
    const snapDistance = ((pieceEl.clientWidth + pieceEl.clientHeight) / 2.0) * 0.333

    if (didCollide(draggie.position.x, draggie.position.y, pieceEl.dataset.x, pieceEl.dataset.y, snapDistance)) {
      draggie.disable()
      draggie.destroy()
      this.lockPiece(pieceEl)
    }
  }

  get lastPuzzleId() {
    return parseInt(storeGet(this.identifier, 'last'))
  }

  set lastPuzzleId(value) {
    storeSet(value, this.identifier, 'last')
  }
}
