import { Controller } from "stimulus"
import { animateCSS } from "helpers/animation"

export default class extends Controller {
  static targets = [
    'setup', 'inputMinutes', 'inputSeconds',
    'startBtn',
    'progress', 'displayMinutes', 'displaySeconds',
    'progressRing'
    ]

  connect() {
    // TODO: Keep track of inProgress...storage?
    this.inProgress = false
    this.updateCurrentView(false)
  }

  updateCurrentView(animated = true) {
    if (this.inProgress) {
      this.setupTarget.classList.add('hidden')
      this.progressTarget.classList.remove('hidden')
      this.startBtnTarget.lastElementChild.innerHTML = 'Stop'
    } else {
      this.setupTarget.classList.remove('hidden')
      this.progressTarget.classList.add('hidden')
      this.startBtnTarget.lastElementChild.innerHTML = 'Start'
    }
  }

  addTime(e) {
    let timeToAdd = parseInt(e.currentTarget.dataset.preset)
    if (e.currentTarget.dataset.preset.substr(-1) == 'm') {
      timeToAdd *= 60
    }
    this.updateInput((timeToAdd + this.duration) * 1000.0)
  }

  timeChanged(e) {
    if (e.currentTarget.value.length > 2) {
      e.currentTarget.value = e.currentTarget.value.slice(0, 2)
    }
  }

  stopTimer() {
    cancelAnimationFrame(this.animationFrameRequestId)
    this.animationFrameRequestId = null

    this.progressRingController.pct = 100
    this.progressRingController.strokeColor = `hsl(200, 80%, 50%)`
    this.timeLeft = 0
    this.displayMinutesTarget.innerHTML = ''
    this.displaySecondsTarget.innerHTML = ')'
    this.startBtnTarget.lastElementChild.innerHTML = 'Back'
    this.displaySecondsTarget.parentElement.style['transform'] = 'translateX(10px) rotate(450deg)';
  }

  minSecFromMS(milliseconds) {
    let seconds = Math.floor(milliseconds / 1000.0)
    let wholeMinutes = Math.floor(seconds / 60.0)
    return { min: wholeMinutes, sec: seconds % 60 }
  }

  updateCountdown(milliseconds = this.timeLeft) {
    let {min, sec} = this.minSecFromMS(milliseconds + 800)
    this.displayMinutesTarget.innerHTML = String(min).padStart(2, '0')
    this.displaySecondsTarget.innerHTML = String(sec).padStart(2, '0')
  }

  updateInput(milliseconds = this.timeLeft) {
    let {min, sec} = this.minSecFromMS(milliseconds)
    this.inputMinutesTarget.value = min
    this.inputSecondsTarget.value = sec
  }

  start(e) {
    this.inProgress = !this.inProgress

    this.updateCurrentView()

    if (this.inProgress) {
      this.updateState('Stop')

      if (this.animationFrameRequestId) { return }

      this.timeLeft = this.duration * 1000.0
      this.progressRingController.pct = 100
      this.progressRingController.strokeColor = `hsl(200, 80%, 50%)`

      this.lastTime = performance.now()
      this.animationFrameRequestId = requestAnimationFrame((ts) => this.loop(ts))

      this.updateCountdown()
    } else {
      this.updateInput(this.timeLeft + 1000)
      this.stopTimer()
      this.updateState('Start')
      this.displaySecondsTarget.parentElement.style['transform'] = null
    }
  }

  reset(e) {
    e.preventDefault();
    this.updateInput(parseInt(e.currentTarget.dataset['seconds']) * 1000)
  }

  updateState(newState) {
    let buttonController = this.application.getControllerForElementAndIdentifier(
      this.startBtnTarget,
      "button"
    )

    this.startBtnTarget.lastElementChild.innerHTML = newState

    if (newState == 'Stop') {
      buttonController.state = 'playing'
    } else {
      buttonController.state = 'default'
    }
  }

  get duration() {
    return (parseInt(this.inputMinutesTarget.value) * 60) + parseInt(this.inputSecondsTarget.value)
  }

  get progressRingController() {
    return this.application.getControllerForElementAndIdentifier(
      this.progressRingTarget,
      'progress-ring'
    )
  }

  loop (timestamp) {
    var elapsedTime = timestamp
    var dt = elapsedTime - this.lastTime
    this.lastTime = elapsedTime
    this.timeLeft -= dt

    let newPct = Math.max(1, this.timeLeft / (this.duration * 1000) * 100.0)
    this.progressRingController.pct = newPct
    this.updateCountdown()

    this.progressRingController.strokeColor = `hsl(${newPct * 2}, 80%, 50%)`

    if (this.timeLeft < 0) {
      this.stopTimer()
    }

    this.animationFrameRequestId = requestAnimationFrame((ts) => this.loop(ts))
  }
}
