import { Controller } from "stimulus"
import { storeGetJSON, storeSetJSON } from 'helpers/storage'
import EventBus from 'js-event-bus'; const eventBus = EventBus()

export default class extends Controller {
  static targets = [ 'sense' ]

  connect() {
    this.storeFetchSenses()

    this.senseTargets.forEach(it => {
      let score = this.getScore(it.dataset.id)
      if (score) {
        this.element.querySelector(`input[name=${it.dataset.id}][value="${score}"]`).setAttribute('checked', true)
      }
    })
  }

  getScore(senseKey) {
    let senseScore = this.senses?.[senseKey]
    if (senseScore) {
      return senseScore
    }
  }

  scoreChanged(e) {
    this.senses[e.currentTarget.name] = e.currentTarget.value
    this.storeCommitSenses()
    eventBus.emit('profileUpdated')
  }

  // Private

  storeCommitSenses() {
    storeSetJSON(this.senses, 'profile', 'sensory')
  }

  storeFetchSenses() {
    this.senses = storeGetJSON('profile', 'sensory') || {}
  }
}
