import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['icon']

  connect() {
    this.update()
  }

  update() {
    const currentState = this.state
    var buttonCls = null

    this.iconTargets.forEach((el, i) => {
      if (el.dataset.buttonCls) {
        if (el.dataset.buttonState == currentState) {
          buttonCls = el.dataset.buttonCls
        } else {
          this.element.classList.remove(...el.dataset.buttonCls.split(' '))
        }
      }
      el.classList.toggle("hidden", el.dataset.buttonState != currentState)
    })

    if (buttonCls) {
      this.element.classList.add(...buttonCls.split(' '))
    }
  }

  get state() {
    return this.data.get('state') || 'default'
  }

  set state(value) {
    this.data.set('state', value)
    this.update()
  }
}
