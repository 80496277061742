export function pictureTag({ imgSizes, alt = "" }) {
  let pictureEl = document.createElement('picture')

  let srcs = []

  for (var key in imgSizes) {
    if (imgSizes.hasOwnProperty(key)) {
      if (key == '1x') {
        srcs.push(`${imgSizes[key]}`)
      } else {
        srcs.push(`${imgSizes[key]} ${key}`)
      }
    }
  }

  let source = document.createElement('source')
  source.srcset = srcs.join(', ')
  pictureEl.appendChild(source)

  let img = document.createElement('img')
  img.src = imgSizes['1x']
  img.alt = alt
  pictureEl.appendChild(img)

  return pictureEl
}
