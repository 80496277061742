import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['button', 'content', 'collapse']

  connect() {
    this.toggleClass = 'hidden'

    this.update()
  }

  toggle(e) {
    this.state = this.state == 'default' ? 'expanded' : 'default'
  }

  update() {
    const currentState = this.state

    if (this.state == 'expanded') {
      this.contentTarget.classList.remove(this.toggleClass)
      this.contentTarget.ariaHidden = false
      this.buttonTarget.ariaExpanded = true
      this.buttonTarget.style['borderBottomLeftRadius'] = 0
      this.buttonTarget.style['borderBottomRightRadius'] = 0
      this.element.classList.add('expanded')
      this.collapseTarget.style['transform'] = null
    } else {
      this.element.classList.remove('expanded')
      this.contentTarget.classList.add(this.toggleClass)
      this.contentTarget.ariaHidden = true
      this.buttonTarget.ariaExpanded = false
      this.buttonTarget.style['borderBottomLeftRadius'] = null
      this.buttonTarget.style['borderBottomRightRadius'] = null
      this.collapseTarget.style['transform'] = 'rotate(90deg)'
    }
  }

  get state() {
    return this.data.get('state') || 'default'
  }

  set state(value) {
    this.data.set('state', value)
    this.update()
  }
}
