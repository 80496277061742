import { Controller } from "stimulus"
import {storeGet, storeSet} from 'helpers/storage'

export default class extends Controller {
  static targets = [ 'tab', 'content' ]

  connect() {
    this.slugs = this.tabTargets.map((it) => it.dataset['slug'] )
    this.update()
  }

  jump(e) {
    const element = e.currentTarget

    if (this.index != element.dataset['index']) {
      this.index = element.dataset['index']
    }
  }

  update() {
    const currentIndex = this.index
    this.tabTargets.forEach((el, i) => {
      if (currentIndex == i) {
        el.classList.add('bg-white')
        el.classList.add('py-8')
        el.classList.remove('bg-dark-20')

        el.classList.remove('hoverable')

        el.style.borderBottomLeftRadius = 0;
        el.style.borderBottomRightRadius = 0;

      } else {
        el.classList.add('bg-dark-20')
        el.classList.remove('py-8')
        el.classList.remove('white')

        el.classList.add('hoverable')

        el.style.borderBottomLeftRadius = null;
        el.style.borderBottomRightRadius = null;
      }
    })

    this.contentTargets.forEach((el, i) => {
      el.classList.toggle("hidden", currentIndex != i)
    })
  }

  get tabCount() {
    return this.tabTargets.length
  }

  get selectedSlug() {
    return storeGet('tab', this.element.id)
  }

  set selectedSlug(value) {
    storeSet(value, 'tab', this.element.id)
  }

  get index() {
    if (this.selectedSlug) {
      return this.slugs.indexOf(this.selectedSlug)
    } else {
      return parseInt(this.data.get("index") || 0)
    }
  }

  set index(value) {
    if (value < 0) {
      this.data.set("index", this.tabCount - 1)
    } else if (value >= this.tabCount) {
      this.data.set("index", 0)
    } else {
      this.data.set("index", value)
    }

    this.selectedSlug = this.slugs[value]

    this.update()
  }
}
