import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ['circle', 'innerContainer'];

  connect() {
    let strokeWidth = parseFloat(this.data.get('strokeWidth')) || 10

    if (strokeWidth >= 0 && strokeWidth <= 100) {
      this.circleTarget.setAttribute('stroke-width', strokeWidth)
      this.circleTarget.setAttribute('r', (100 - strokeWidth) / 2.0)
    }

    let circumf = this.circumference
    this.circleTarget.style.strokeDasharray = `${circumf} ${circumf}`;
    this.circleTarget.style.strokeDashoffset = `${circumf}`;

    // this.test()

    this.update()
  }

  update() {
    let largerDimen = Math.max(this.element.parentElement.clientWidth, this.element.parentElement.clientHeight)

    this.element.style['height'] = largerDimen + 'px'
    this.element.style['width'] = largerDimen + 'px'

    if (this.hasInnerContainerTarget) {
      this.innerContainerTarget.style['border-width'] = `${largerDimen * ((parseFloat(this.data.get('strokeWidth')) || 10) / 100.0)}px`
    }

    let offset = this.circumference - this.pct / 100 * this.circumference
    this.circleTarget.style.strokeDashoffset = offset
  }

  get pct() {
    return parseFloat(this.data.get('pct')) || 100
  }

  set pct(value) {
    if (value >= 0 && value <= 100) {
      this.data.set('pct', value)
      this.update()
    }
  }

  get radius() {
    return this.circleTarget.r.baseVal.value
  }

  get circumference() {
    return this.radius * 2.0 * Math.PI
  }

  set strokeColor(value) {
    this.circleTarget.setAttribute('stroke', value)
  }

  // Private

  test() {
    var counter = 100
    let testInterval = setInterval(() => {
      this.pct = counter--
      if (counter > 0) {
        this.strokeColor = `hsl(${counter * 3}, 50%, 68%)`
      } else {
        this.strokeColor = 'unset'
        this.update()
        clearInterval(testInterval)
      }
    }, 1000)
  }
}
