import { Controller } from "stimulus";
import { animateCSS } from "helpers/animation"

export default class extends Controller {
  static targets = ['item', 'input', 'feedback']

  connect () {
    this.filter(null)
  }

  async filter(e) {
    if (e) {
      e.preventDefault()
    }
    this.itemTargets.forEach((el) => el.classList.toggle(this.data.get("class"), !el.innerText.toLowerCase().includes(this.filterString)))
    if (this.feedbackTarget) {
      this.feedbackTarget.classList.toggle(this.data.get("class"), this.visibleItems.length > 0)
    }
  }

  get filterString() {
    return this.inputTarget.value.toLowerCase().trim()
  }

  get visibleItems() {
    return this.itemTargets.filter(function (el) {
      return !el.classList.contains('hidden');
    })
  }
}
