import { Controller } from "stimulus"
import { assetPath } from 'helpers/assets'
import { storeGet } from 'helpers/storage'

export default class extends Controller {
  static targets = [ 'title', 'img' ]

  connect() {
    this.update()
  }

  update() {
    if (this.emotionKey) {
      this.titleTarget.innerHTML = `I am feeling <u>${this.emotionKey.toUpperCase()}</u>`
      this.imgTarget.src = assetPath('feeling', `ic_feeling_${this.emotionKey}.svg`)
    } else {
      this.titleTarget.innerHTML = `I am feeling <u>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;?&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</u>`
    }
  }

  get emotionKey() {
    return storeGet('emotion-picker')
  }
}
