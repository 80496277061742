import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ 'picker' ]

  connect() {
    this.feedback = {}
    this.pickerTargets.forEach(it => {
      let value = this.getValue(it.dataset.id)
      if (value) {
        this.element.querySelector(`input[name=${it.dataset.id}][value="${value}"]`).setAttribute('checked', true)
      }
    })
  }

  getValue(pickerKey) {
    let pickerValue = this.feedback?.[pickerKey]
    if (pickerValue) {
      return pickerValue
    }
  }

  valueChanged(e) {
    this.feedback[e.target.name] = e.target.value
  }
}
